#options-area {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem 0;
    row-gap: .4rem;
    z-index: 10;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);

    background-color: var(--clr-fourth);
    box-shadow: 0 0 5px var(--clr-third);
    width: 3rem;
    --width: 4rem;
    --popup-offset: 0rem;
    --off: calc(var(--popup-offset) / 2);

    transition: width .2s ease-in, top 0.5s
}

#options-area.minimized {
    --minimized-width: 2rem;
    top: 100%;
    transform: translateY(-100%);
    padding: 0;
    width: var(--minimized-width);
    height: var(--minimized-width);
    background-color: transparent;
    justify-content: center;
    box-shadow: none;
    cursor: pointer;
}

#options-area.minimized:hover {
    background-color: var(--clr-third);
}

#options-area.minimized > *:not(#options-area__hide-handler) {
    display: none
}
#options-area.minimized #options-area__hide-handler:hover {
    background-color: var(--clr-third);
}
#options-area.minimized #options-area__hide-handler {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#options-area.minimized #options-area__hide-handler .option-popup div {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
}


#options-area:not(.minimized):hover{
    width: var(--width);
}



.options-area__child {
    position: relative;
    /* aspect-ratio: 1 / 1;
    margin: .5rem 0; */
}

.options-area__child .option-popup {
    display: none;
    z-index: 15;
}

.options-area__child:hover .option-popup {
    display: block;
    position: absolute;
    max-width: 20rem;
    left: calc(100% + var(--popup-offset));
    top: 50%;
    transform: translateY(-50%);
}

.option-popup > *{
    background-color: var(--clr-third);
    font-size: .7rem;
    padding: .5rem;
    border-radius: 1rem;
    color: var(--clr-fourth);
    transform-origin: left;
    animation: opacity .3s
}

@keyframes opacity {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.options-area__child .svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.options-area__child .svg-container svg{
    width: 50%;
    aspect-ratio: 1 / 1;
}

.options-area__child:hover .svg-container {
    background-color: var(--clr-third);
    color: white;
}

/* .option-popup::before {
    content: "";
    position: absolute;
    left: calc(-1 * var(--width) - var(--popup-offset));
    top: calc(50% - var(--width) / 2);
    width: calc(var(--width) + var(--popup-offset));
    height: var(--width);
    background-color: red;
} */

/* .option-popup::before {
    content: "";
    position: absolute;
    --size: 1rem;
    left: calc(-1 * var(--size));
    top: calc(50% - 1.2rem);
    transform: translateY(-50%);
    width: var(--size);
    height: var(--size);
    background-color: red;
} */



/****** STAT BUTTONS  ***/


#options-area__stat-selections{
    padding: .5rem;
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: .3rem;
}

#options-area__stat-selections button{
    width: 2.5rem;
    height: 1.2rem;
    background-color: var(--clr-fourth);
    color: var(--clr-primary);
    border: none;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    font-size: .7rem;
    font-weight: 700;
}

#options-area__stat-selections button.deactivated {
    background-color: hsl(0, 0%, 83%);
    color: var(--clr-fourth)
}

#options-area__stat-selections button:hover {
    outline: 2px solid var(--clr-primary);
    animation: shake .1s;
}


#options-area .file-upload-button {
    border-radius: .3rem;
    border: 1px solid var(--clr-fourth);
}

#options-area__hide-handler {
    cursor: pointer;
}

#options-area__hide-handler .option-popup > *{
    width: 10rem;
    border-radius: .5rem
}

