
.navbar-container {
    display: flex;
    align-items: center;
    background-color: var(--clr-secondary);
    opacity: 0.9;
    box-shadow: 0 1px 2px var(--clr-secondary);
    color: var(--clr-fourth);
    font-size: var(--fs-secondary-heading);
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    padding: 1rem 0;
    font-weight: 700;
}

.navbar-container h1 {
    font-size: 1.2rem;
}

.navbar-container.poster-active {
    position: absolute;
}

.navbar-site-name-container {
    flex: 1 1 0;
    padding-left: 3rem;
}

.navbar-page-links-container {
    flex: 1 1 0;
    display: flex;
    justify-content: space-around;
}

.navbar-link {
    color: var(--clr-fourth);
    padding: .7rem 1.6rem;
    transition: color, background-color .2s ease-in;
}

.navbar-link.active-nav {
    color: var(--clr-secondary);
    background-color: var(--clr-fourth);
}

@media (max-width: 600px){
    .navbar-container {
        flex-direction: column;
        align-items: stretch;
    }
    .navbar-site-name-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
}

@media (min-aspect-ratio: 8/5) {
    .navbar-container {
        position: relative;
    }
}