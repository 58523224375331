@import "setup.css";
@import "navbar.css";
@import "input-field.css";

.app-container {
    display: flex;
    flex-direction: column;
    width: min(100vw, 2000px);
}

body {
    overflow-x: hidden;
}

main {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-2deg); }
    20% { transform: translate(-3px, 0px) rotate(2deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(2deg); }
    50% { transform: translate(-1px, 2px) rotate(-2deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-2deg); }
    80% { transform: translate(-1px, -1px) rotate(2deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-2deg); }
  }