@import "options.css";
@import "player-name.css";
@import "utilities.css";
@import "font-handler.css";
@import "image-resizer.css";
@import "background-picker.css";
@import "file-upload.css";

.poster-page__player-name-section {
    cursor: pointer;
}

.poster-page__player-name-section:hover {
    text-decoration: line-through;
}

.grow-shrink {
    flex: 1 1;
}

* {
    min-width: 0;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.poster-loading {
    animation: animate-opacity 3s infinite ease-in-out;
}

@keyframes animate-opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.poster-area {
    position: relative;
    z-index: 1;
    animation: from-right .5s ease-out;
}

#options-area {
    animation: from-left .5s ease-out;
}

@keyframes from-right {
    0% {
        transform: translateX(120%);
    }
    50% {
        transform: translateX(120%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes from-left {
    from {
        transform: translate(-100%, -50%);
    }
    to {
        transform: translateX(0, -50%);
    }
}

.stat-displayer-panel {
    display: grid;
	grid-template-columns: 1fr;
	position: relative;
}


.stat-individual {
    display: grid;
	grid-template-columns: 1fr 1.4fr 1fr;
    grid-template-rows: 1fr;
	align-items: center;
}

.stat-span {
	z-index: 5;
}

:where(.stat-span:nth-child(1)) {
    text-align: left;
}
:where(.stat-span:nth-child(2)) {
    text-align: center;
}
:where(.stat-span:nth-child(3)) {
    text-align: right;
}

@media (max-width: 800px) {
    .poster-area {
        flex-direction: column;
    }
    .stat-displayer-panel {
        display: flex;
        justify-content: space-around;
    }
    .stat-individual {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1.4fr 1fr;
    }
    .stat-span {
        text-align: center
    }
}

.poster-background {
    z-index: 2;
}




/* .poster-area::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.4;
    z-index: 0;
} */