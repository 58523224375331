.notification-wrapper {
    position: fixed;
    --width: 15rem;
    width: var(--width);
    z-index: 1000;
}

.notification-item {
    box-shadow: 0 0 10px rgba(0,0,0, 0.3);
    overflow: hidden;
    margin-bottom: 20px;
    transition: transform 0.4s;
    width: var(--width);
    cursor: pointer;
    color: white;
}



.notification-wrapper[data-position$="right"] .notification-item {
    transform: translateX(110%);
}

.notification-wrapper[data-position$="left"] .notification-item {
    transform: translateX(-110%);
}

.notification-wrapper .notification-item.show {
    transform: translateX(0%);
    animation: shake .2s .4s;
}

.notification-item.exit {
    animation: SlideOut 0.4s;
    animation-fill-mode: forwards;
}

.notification-item p {
    margin: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
}

.notification-item .bar {
    height: 5px;
}

.notification-item .bar {
    background-color: white;
}

.notification-item.success {
    background-color: #21e924;
}

.notification-item.error {
    background-color: #eb4301;
}

.notification-item.fancy .bar {
    background-image: linear-gradient(to right, red, blue, purple)
}

.notification-item svg {
    min-width: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    margin-right: 1rem;
}