
.stats-tables-container {
    margin: .5rem 1rem;
}

.stats-table-container {
    border-radius: 5px;
    margin: 3rem auto;
    padding-bottom: 1rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow-x: auto;
    overflow-y: hidden;
    display: grid;
    grid-template-columns: auto;
    align-items: stretch;

    transform-origin: top;
    animation: scale-up .6s ease-out;
    
}

@keyframes scale-up {
    from {
        transform: translateX(-100%)
    }
    to {
        transform: translateX(0);
    }
}


.stats-table-header {
    --left-margin: 1rem;
    color: var(--clr-secondary);
    margin: .5rem auto 0 var(--left-margin);
    position: sticky;
    left: var(--left-margin);
}

.stats-table-header:hover,
.stats-table-header:focus {
    color: var(--clr-danger);
    text-decoration: line-through;
    cursor: pointer;
}

.stats-table {
    border-collapse: collapse;
    margin: 1rem 1rem;
    font-size: 0.7em;
    border-radius: 5px 5px 0 0;
    scroll-behavior: smooth;
    transition: margin  250ms linear;

    --row-padding: .4rem;
    position: relative;
}

/* --- TOP SECTION--- */

.stats-table-top {
    display:flex;
    justify-content: flex-end;
    position: sticky;
    left: 0;
}

/* --- BUTTON --- */

.stats-table-top button {
    cursor: pointer;
    text-decoration: none;

    --button-size: 3em;

    color: var(--clr-fourth);
    background-color: var(--clr-secondary);
    border: none;
    height: var(--button-size);
    width: var(--button-size);
    padding: 0 .5em;
    font-size: var(--fs-500);
}

.stats-table-top .button-delete {
    border-radius: 0 5px 0 0;
    position:sticky;
    right: 0;
}

.stats-table-top .button-minimize {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: sticky;
    right: var(--button-size);
}

/* --- MAIN TABLE LAYOUT --- */

.stats-table thead tr {
background-color: var(--clr-third);
color: #ffffff;
}

.stats-table th,
.stats-table td {
padding: var(--row-padding) .8em;
text-align: center; 
}

.stats-table tbody tr {
border-bottom: 1px solid #dddddd;
}

.stats-table tbody tr:nth-of-type(even) {
background-color: #f3f3f3;
}

.stats-table tbody tr:last-of-type {
border-bottom: 2px solid #009879;
}

/* --- ANIMATION --- */

.stats-table-top .button-minimize:hover,
.stats-table-top .button-minimize:focus {
    background-color: var(--clr-third);
}

.stats-table-top .button-delete:hover,
.stats-table-top .button-delete:focus {
    background-color: var(--clr-danger);
}


.stats-table-top .button-minimize::before, 
.stats-table-top .button-minimize::after {
    content: attr(data-content);
    transition: transform 200ms ease-in;
}

.stats-table-top .button-minimize.minimized::before {
    transform: translateY(70%); 
}

.stats-table-top .button-minimize.minimized::after {
    transform: translateY(-70%); 
}

.stats-table-top .button-minimize.extended::before {
    transform: translateY(100%); 
}

.stats-table-top .button-minimize.extended::after {    
    transform: translateY(-100%); 

}

.stats-table.minimized-table {
    margin-bottom: calc(var(--row-count) * var(--row-padding) * -5.5);
}

.stats-table__getting-stats {
    overflow-x: hidden;
}

.stats-table__getting-stats::after {
    content: "";
    position: absolute;
    left: 10%;
    bottom: -3px;
    height: 3px;
    width: 80%;
    background-image: linear-gradient(to right,yellow, green, blue, red);
    animation: loading 2s infinite alternate;
}

@keyframes loading {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(100%);
    }
}

.stats-table__data--header {
    cursor: pointer;
}

.stats-table__data--header:hover {
    background-color: var(--clr-secondary);
}

.stats-table .stats-table__stat--row:hover,
.stats-table__clicked--row {
    background-color: hsl(2, 50%, 80%);
}