.input-field {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    position: relative;
}

/* dot dot dot */
.loading::after {
    content: ".......";
    position: absolute;
    left: 88%;
    top: 0%;
    height: 100%;
    overflow: hidden;
    animation: change-width 2s infinite;
    color: var(--clr-primary);
    font-weight: 700;
    
}

@keyframes change-width {
    0% {
        width: 0%;
    }

    100% {
        width: 8%;
    }
}


.dropdown {
    position: absolute;
    top: 100%;
    border: 1px solid var(--clr-primary);
    border-top: none;
    border-radius: 0 0 .2em .2em;
    z-index: 1;
    transform: translateY(-10px); /* To fix weird bug */
}

.dropdown .dropdown__option:last-of-type {
    border-bottom: none;
}

.input-with-dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: .2rem;
}

.dropdown__option {
    border-bottom: 1px solid var(--clr-third);
    padding: .2em 1em;
    cursor: pointer;
}

.dropdown__option:hover {
    background-color: var(--clr-primary);
    color: var(--clr-fourth);
    font-weight: 700;
}

.input-field input,
.dropdown__option {
    background-color: var(--clr-fourth);
    color: var(--clr-primary);
    font-size: .8em;
    padding: .5em 1em;
}

.input-field input {
    border: 1px solid var(--clr-primary);
    border-bottom: 1px solid var(--clr-fourth);
    border-radius: .2em .2em 0 0;
    outline: none;
    margin-bottom: 10px;  /* To fix weird bug */
}

.input-field input.empty {
    border-bottom: 1px solid var(--clr-primary);
    border-radius: .2em;
}

.dropdown, .input-field input {
    width: 100%;
}

.input-field input:hover,
.input-field input:focus {
    background-color: rgba(0,0,0,0.05);
}
