.image-resizer-container {
    display: flex;
}

.image-resizer-container input[type=range] {
    width: 7rem;
}

.image-field-set {
    border: 1px solid rgba(255,255,255, 0.4);
    border-radius: .2rem;
    padding: 1rem;
}

.image-field-set legend {
    padding: 0;
}

.image-field-set button {
    padding: .5rem 1rem;
}

.shadow-picker > *{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.shadow-picker input[type=number] {
    width: 3rem;
    height: 2rem;
    font-size: .7rem;
    padding: 0 .5rem;
    border: 1.5px solid var(--clr-primary);
    border-radius: 2px;
    text-align: center;
}

